'use strict';

import $ from 'jquery';

export default class ContactInfo {
	constructor() {
		// let $table = $('.contact-info__layout');

		// $table.stackcolumns();
	}
}
