'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import 'jquery-lazyload';
import StaffTabs from '../../molecules/staff-tabs/staff-tabs';

export default class HomeAbout {
	constructor() {

		this.$gridContent = $('.grid-content');

		this.$gridContent.matchHeight();

		let staffTabs = new StaffTabs();
		let $staffTabs = $('.staff-tabs');

		let $ourTeamContent = $('#ourTeamContent');

		let oldTarget = '';

		$staffTabs.find('a').map((i, ele) => {
			let $this = $(ele);

			$this.on('click', e => {
				e.preventDefault();

				let target = $this.attr('href');

				if (target === oldTarget) {
					$ourTeamContent.removeClass('content-shown');
					this.hideContent(target);
					oldTarget = '';
				} else {
					if ($ourTeamContent.hasClass('content-shown')) {
						this.showContent(target);
					} else {
						$ourTeamContent.addClass('content-shown');
						this.showContent(target);
					}
					oldTarget = target;
				}
			});
		});

		$('.grid-bg-img').lazyload({
			threshold: 200,
			effect: 'fadeIn'
		});
	}

	hideContent(target) {
		$(target).removeClass('is-shown').hide().removeClass('is-visible');
		this.hideImage(target);
	}

	hideImage(target) {
		let staffId = target.replace('#', '');
		$('#staff-' + staffId).removeClass('active');
	}

	showContent(target) {
		if (!$(target).hasClass('is-visible')) {
			$(target).siblings('.is-visible').removeClass('is-visible');

			$(target).siblings('.is-shown')
				.removeClass('is-shown')
				.hide();
				// .removeClass('is-visible');

			$(target).addClass('is-shown');
			$(target).show(0, () => {
				$(target).addClass('is-visible');
				this.showImage(target);

				// this.$gridContent.matchHeight();
				// this.$gridContent.matchHeight._maintainScroll = true;
			});
		}
	}

	showImage(target) {
		let staffId = target.replace('#', '');
		$('#staff-' + staffId).siblings('.active').removeClass('active');
		$('#staff-' + staffId).addClass('active');
	}
}
