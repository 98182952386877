'use strict';

import $ from 'jquery';
import ContactMap from '../../molecules/contact-map/contact-map';
import ContactInfo from '../../molecules/contact-info/contact-info';

export default class HomeContact {
	constructor() {
		if ($('.contact-map').length) {
			new ContactMap();
		}

		// if ($('.contact-info').length) {
		// 	new ContactInfo();
		// }
	}
}
