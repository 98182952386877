'use strict';

import $ from 'jquery';

export default class SiteFooter {
	constructor() {
		let $siteFooterLogo = $('.site-footer__logo'),
		$siteHeader = $('.site-header'),
		$link = $('a', $siteFooterLogo);

		$link.on('click', (e) => {
			e.preventDefault();

			let target = $link.attr('href');

			$('html, body').stop().animate({
				scrollTop: $(target).offset().top - $siteHeader.outerHeight()
			}, 500);

			$('.primary-nav li').removeClass('active');
		});

	}
}
