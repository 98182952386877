'use strict';

import $ from 'jquery';

export default class SiteHeader {
	constructor() {

		let $siteHeader = $('.site-header');
		this.$primaryNav = $('.primary-nav');
		this.$pageAnchor = $('.page-anchor.primary-nav');	// removing .page-anchor class from markup will return the primary-nav back to normal navigation

		$('ul li', this.$pageAnchor).map((i, ele) => {
			let $this = $(ele);

			$this.find('a').on('click', e => {
				let target = $this.find('a').attr('href');

				e.preventDefault();

				if (!$this.hasClass('active')) {
					this.setActive(target);

					$('html, body').stop().animate({
						scrollTop: $(target).offset().top - $siteHeader.outerHeight(true) + 1
					}, 500);
				}
			});
		});

		this.$mobileMenuBtn = $('.btn-mobile-menu', $siteHeader);

		this.$mobileMenuBtn.on('click', e => {
			e.preventDefault();
			let show = 0;

			if (this.$mobileMenuBtn.hasClass('active')) {
				this.hideMobileMenu();
			} else {
				this.showMobileMenu();
			}
		});

		$(document).on('click', e => {
			let $eTarget = $(e.target);

			if (!$eTarget.closest('.primary-nav').length && !$eTarget.closest('.btn-mobile-menu').length) {
				this.hideMobileMenu();
			}
		});

		/* Bind URL upon scroll */
		$(window).on('load', () => {
			let sections = [];
			$('.home-section').map((i, ele) => {
				let section = {
					target: '#' + $(ele).attr('id'),
					position: $(ele).offset().top
				}

				sections.push(section);
			});

			let scrollTimeout;

			$(window).on('scroll.primaryLink', () => {
				clearTimeout(scrollTimeout);

				scrollTimeout = setTimeout(() => {
					let $windowPos = $(window).scrollTop() + $siteHeader.outerHeight();

					if ($windowPos < sections[0].position) {
						// before first section
						this.resetActive();
					} else if ($windowPos + $(window).height() >= $('body').outerHeight()) {
						// end of the page
						this.setActive(sections[sections.length - 1].target);
					} else {
						// other sections
						for (let i = sections.length - 1; i >= 0; i--) {
							if ($windowPos >= sections[i].position) {
								this.setActive(sections[i].target);
								break;
							}
						}
					}
				}, 200);
			});
		});
	}

	showMobileMenu () {
		this.$primaryNav.addClass('menu-open');
		this.$mobileMenuBtn.addClass('active')
	}

	hideMobileMenu() {
		this.$primaryNav.removeClass('menu-open');
		this.$mobileMenuBtn.removeClass('active');
	}

	setActive(target) {
		this.resetActive();
		this.$primaryNav.find('a[href="' + target + '"]').parent().addClass('active');

		let pageURL = window.location.href,
		hash = window.location.hash;

		let newPageURL = pageURL.replace(hash, target);

		if (typeof window.history !== 'undefined' && typeof window.history.pushState !== 'undefined') {
			window.history.pushState({}, '', newPageURL);
		}

	}

	resetActive() {
		this.$primaryNav.find('li').removeClass('active');
	}
}
