'use strict';

import $ from 'jquery';
import doT from 'dot';

export default class StaffTabs {
	constructor() {

		this.$staffTabs = $('.staff-tabs');

		this.bindButton();

	}

	setItemActive($item) {
		$item.siblings('li.active').removeClass('active');
		$item.addClass('active');
	}

	setItemInactive($item) {
		$item.removeClass('active');
	}

	// renderTemplate(data) {
	// 	let template = `
	// 		{{~it :staff:index}}
	// 			<li><a class="btn btn-stroke" href="#{{=staff.id}}">{{=staff.name}}</a></li>
	// 		{{~}}
	// 	`;

	// 	let dotTemplate = doT.template(template);

	// 	let renderedHTML = dotTemplate(data);

	// 	this.$staffTabs.html(renderedHTML);

	// 	this.bindButton();
	// }

	bindButton() {
		this.$staffTabs.find('a').map((i, ele) => {
			let $this = $(ele);

			$this.on('click.staffNav', e => {
				e.preventDefault();

				if ($this.parent().hasClass('active')) {
					this.setItemInactive($this.parent());
				} else {
					this.setItemActive($this.parent());
				}
			});
		})
	}
}
