// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';

import SiteHeader from '../_modules/organisms/site-header/site-header';
import SiteFooter from '../_modules/organisms/site-footer/site-footer';
import HomeAbout from '../_modules/organisms/home-about/home-about';
import HomeEvents from '../_modules/organisms/home-events/home-events';
import HomeMenu from '../_modules/organisms/home-menu/home-menu';
import HomeGallery from '../_modules/organisms/home-gallery/home-gallery';
import HomeContact from '../_modules/organisms/home-contact/home-contact';

$(() => {
	// Document Ready

	new SiteHeader();

	new HomeAbout();

	new HomeEvents();

	new HomeMenu();

	new HomeContact();

	new HomeGallery();

	new SiteFooter();

});
