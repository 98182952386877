'use strict';

import $ from 'jquery';

export default class ContactMap {
	constructor() {
		this.map;

		this.marker;

		let script = document.createElement('script'),
		scriptSrc = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAJuUhTEeU_009V3PGZyafxuCA92EVEKj8&v=3.exp&callback=window.renderMap';
		script.type = 'text/javascript';
		script.src = scriptSrc;
		document.body.appendChild(script);

		window.renderMap = this.renderMap;	// export to global enable to callback
	}

	renderMap() {
		let mapContainer = document.getElementById('contactMap'),
		longitude = $(mapContainer).data('lng'),
		latitude = $(mapContainer).data('lat');

		let mapCenter = {
			lat: latitude,
			lng: longitude
		};

		this.map = new google.maps.Map(mapContainer,
		{
			center: mapCenter,
			zoom: 17,
			scrollwheel: false,
			mapTypeControl: false,
			streetViewControl: false,
			styles: [
				{
					elementType: 'geometry',
					stylers: [
						{
							color: '#f2f2f2'
						}
					]
				},
				{
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#000000'
						}
					]
				},
				{
					featureType: 'poi',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road',
					elementType: 'geometry',
					stylers: [
						{
							color: '#ffffff'
						}
					]
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry',
					stylers: [
						{
							color: '#e9e9e9'
						}
					]
				},
				{
					featureType: 'water',
					elementType: 'geometry',
					stylers: [
						{
							color: '#fff7f7'
						}
					]
				},
				{
					featureType: 'landscape.man_made',
					stylers: [
						{
							visibility: 'off'
						}
					]
				}
			]
		});

		this.marker = new google.maps.Marker({
			map: this.map,
			position: mapCenter,
			animation: google.maps.Animation.DROP,
			icon: '/assets/images/marker.png'
		});
	}
}
