'use strict';

import $ from 'jquery';
import doT from 'dot';
import 'slick-carousel';
import 'jquery-stellar';

export default class HomeGallery {
	constructor() {

		this.photoSource = photos;	// source in the HTML

		this.$gallery = $('.home-gallery__photos');

		this.$photoList = $('.photo-list', this.$gallery);

		this.photoSetId = 1;

		this.bindRefreshBtn();

		// render mobile view onload
		this.renderMobile(this.photoSource);

		$.stellar();
	}

	bindRefreshBtn() {
		this.$refreshBtn = $('.btn-refresh', this.$gallery);

		this.$refreshBtn.on('click', e => {
			e.preventDefault();

			if (this.photoSetId >= this.photoSource.length) {
				this.photoSetId = 0
			}

			this.loadDesktopPhotoSet(this.photoSetId);
		});
	}

	loadDesktopPhotoSet(id) {
		let sources = this.photoSource[id];

		sources.square.map((photo, i) => {
			$('#photo11' + (i + 1)).data('imgsource', sources.square[i].source);
		});

		sources.tall.map((photo, i) => {
			$('#photo12' + (i + 1)).data('imgsource', sources.tall[i].source);
		});

		$('#photo211').data('imgsource', sources.wide[0].source);

		this.$photoList.find('.photo img').not('.photo-pattern img').not('.photo-empty img').not('.control img').addClass('fade-out');

		setTimeout(() => {
			this.$photoList.find('.photo img').not('.photo-pattern img').not('.photo-empty img').not('.control img').each(function() {
				let newPhoto = $(this).data('imgsource');
				$(this).attr('src', newPhoto);
				$(this).removeClass('fade-out');
			});
		}, 500);

		this.photoSetId++;
	}

	renderMobile(data) {
		let mobileTemplate = `
			<ul class="photo-list mobile-gallery">
			{{~it :photo}}
			<div class="slick-group">
			<li class="photo photo-1-2"><img src="{{=photo.tall[0].source}}" alt="{{=photo.tall[0].name}}" /></li>
			<li class="photo photo-1-1"><img src="{{=photo.square[0].source}}" alt="{{=photo.square[0].name}}" /></li>
			<li class="photo photo-1-1"><img src="/assets/images/gallery/grid-pattern.jpg" alt="Grid Pattern" /></li>
			</div>
			<div class="slick-group">
			<li class="photo photo-1-1"><img src="{{=photo.square[1].source}}" alt="{{=photo.square[1].name}}" /></li>
			<li class="photo photo-1-1"><img src="{{=photo.square[2].source}}" alt="{{=photo.square[2].name}}" /></li>
			<li class="photo photo-2-1"><img src="{{=photo.wide[0].source}}" alt="{{=photo.wide[0].name}}" /></li>
			</div>
			<div class="slick-group">
			<li class="photo photo-1-1"><img src="{{=photo.square[3].source}}" alt="{{=photo.square[3].name}}" /></li>
			<li class="photo photo-1-2 pull-right"><img src="{{=photo.tall[1].source}}" alt="{{=photo.tall[1].name}}" /></li>
			<li class="photo photo-1-1"><img src="/assets/images/gallery/grid-pattern2.jpg" alt="Grid pattern 2" /></li>
			</div>
			{{~}}
			</ul>
		`;

		let dotTemplate = doT.template(mobileTemplate);

		let _renderedHTML = dotTemplate(data);

		this.$photoList.after(_renderedHTML);

		$('.photo-list.mobile-gallery').slick({
			arrows: false,
			dots: true
		});

	}
}
