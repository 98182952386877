'use strict';

import $ from 'jquery';

import MenuNav from '../../molecules/menu-nav/menu-nav';

export default class HomeMenu {
	constructor() {

		let menuNav = new MenuNav();
		let $menuNav = $('.menu-nav'),
		$menuTabs = $('.menu-nav__tabs', $menuNav),
		$menuLinks = $('a', $menuTabs);

		$menuLinks.map((i, ele) => {
			let $this = $(ele);

			$this.on('click', e => {
				e.preventDefault();

				let target = $this.attr('href');

				if (!$(target).hasClass('active')) {
					$(target).siblings('.active').stop().hide(0, () => {
						$(target).siblings('.active').removeClass('active').removeClass('transit-in');
					})
					$(target).stop().show(0, () => {
						$(target).addClass('active')
						$(target).addClass('transit-in');
					});
				}
			});
		});

		$menuTabs.find('li:first-child a').trigger('click');
	}
}
