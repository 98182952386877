'use strict';

import $ from 'jquery';
import 'jquery-match-height';

export default class HomeEvents {
	constructor() {

		let $eventBox = $('.event-box'),
		$eventContent = $('.event-box__content');

		$eventContent.matchHeight();

	}
}
