'use strict';

import $ from 'jquery';

export default class MenuNav {
	constructor() {

		this.$menuTabs = $('.menu-nav__tabs');
		this.$menuLinks = $('a', this.$menuTabs);

		this.bindButton();

	}

	setItemActive($item) {
		$item.siblings('.active').removeClass('active');
		$item.addClass('active');
	}

	bindButton() {

		this.$menuLinks.map((i, ele) => {
			let $this = $(ele);

			$this.on('click.menuTabs', e => {
				e.preventDefault();

				if (!$this.parent().hasClass('active')) {
					this.setItemActive($this.parent());
				}
			});
		});
	}
}
